import(/* webpackMode: "eager" */ "/opt/app/node_modules/normalize.css/normalize.css");
;
import(/* webpackMode: "eager" */ "/opt/app/public/static/fonts/font-faces.css");
;
import(/* webpackMode: "eager" */ "/opt/app/public/static/icons/icons.css");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/reset-css/reset.css");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Mulish\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"mulish\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/src/app/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/src/modules/app-module/components/GlobalComponents/GlobalComponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/src/modules/app-module/components/GlobalProviders/GlobalProviders.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/src/styles/styles.css");
